
import { defineComponent } from 'vue'
import CourseAll from './cpns/course-all.vue'
import Breadcrumb from '@/components/breadcrumb'

export default defineComponent({
  components: {
    Breadcrumb,
    CourseAll
  },
  setup() {
    return {}
  }
})
