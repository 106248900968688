import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-09a7799f")
const _hoisted_1 = { class: "course-category-page" }
const _hoisted_2 = { class: "container-1140" }
const _hoisted_3 = { class: "course-category-breadcrumb" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_course_all = _resolveComponent("course-all")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_breadcrumb, {
          title: `${_ctx.$filters.hh_t('allCourseTitle')}`
        }, null, 8, ["title"])
      ]),
      _createVNode(_component_course_all)
    ])
  ]))
}